import React, { useEffect, useRef, useState } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./skillzgames.module.css"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const SkillzPageFAQ = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    document.body.classList.add(styles.gamePage)
    const timeout = setTimeout(() => {
      document.body.classList.add(styles.stopTransition)
    }, 400)
    return () => {
      clearTimeout(timeout)
      document.body.classList.remove(styles.gamePage)
      document.body.classList.remove(styles.stopTransition)
    }
  }, [])

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Skillz Game List FAQs"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <div className={styles.list}>
        <div className={styles.pageHeader}>
          <p>
            Return to
            <Link to="/skillzgames" title="Skillz Game List">
              {" "}
              Skillz Game List
            </Link>
          </p>
          <h3>Skillz Games List FAQs</h3>
          <h6>⚠️ The Future of this Game List ⚠️</h6>
          <p>
            Sadly, the Skillz platform has not proven successful for us. So we
            are considering parting ways so that our time can be invested in
            something more profitable. Later this year when sit down to plan out
            where to spend our time in the future, if Skillz does not stay in
            that plan, we will be shutting down this portion of our site. With
            that said, we want to thank everyone who has enjoyed using our site
            to find their favorite new Skillz game. And we especially want to
            thank the small handful of you that have reached out to help us keep
            this list updated throughout the years.
          </p>
          <h6>What gets included on this list? 🖋</h6>
          <p>
            The list includes US compatible powered by Skillz apps. Our goal is
            to maintain the list so as to only include currently available apps.
            So if we discover that a game is no longer available for a new user
            to download, we will remove them from this list.
          </p>
          <h6>How is your list ordered? 🗒</h6>
          <p>
            When you first arrive on the page, it is a somewhat organized and
            yet still randomized order. Our eventual goal is to group like items
            together. For example, if you really like bubble shooter type games,
            we try to place them next to each other to make it easier for you
            find other similar games. We obviously haven't gotten all like games
            next to each other yet, but rest assured, it is something we are
            slowly working on.
          </p>
          <p>
            To change the sort order of the list, select from the category
            titles at the top of the list: Title or Year Published. If you were
            to select Year Published, it would sort the list by the app's
            release date, placing the newest apps first. If you select Year
            Published again, it will reverse the order. The same toggle behavior
            is true when sorting by Title.
          </p>
          <p>
            For those wanting to, our category titles also have the added
            ability to filter the list by availablity for either iOS or Android
            platforms as well as filtering by Cash Enabled.{" "}
            <strong>Bonus Tip</strong>: to see only games that are offered on{" "}
            <em>both</em> platforms, select both iOS and Android filters.
          </p>
          {/* <h6>How do you choose the Featured Game? 🎉</h6>
            <p>
            The player that takes first place in the Boxes leaderboard will have
            the opportunity to be the featured player and therefore feature
            their favorite game on our Skillz Games List page. The leader board
            resets every 72 hours, so this section could feature someone new
            every 3 days. If you have placed first when the leaderboard ends and
            wish to choose an active Skillz game to be featured, you will need
            to private message player Imitating or Fourty2 through any chat
            enabled Skillz game. If the last winner of the leaderboard does not
            wish to be featured, the previous leaderboard winner will remain.
          </p> */}
          <h6>What does the 💰 symbol mean on some of the listed games?</h6>
          <p>
            This game is cash enabled and offers the option to play games for
            cash depending on your location. Please note that just because cash
            games are available you do not need to play for cash. You can still
            play for free using Skillz's Z based bets.
          </p>
          <h6>What does the ✉️ symbol mean on some of the listed games?</h6>
          <p>
            Tapping this icon should redirect you to your built-in mail client
            so that you can reach out directly to the developer of that game.
          </p>
          <p>
            There are several reasons you may want to reach out to the developer
            of an individual game. Are there features you love or perhaps really
            dislike about a game? Have you potentially found a bug with game
            play? Most developers truly want to make a game you love and they
            really want to know about any bugs you may find so that they can fix
            them as soon as possible. Just be prepared to be available for any
            follow up questions that developer might have for you after reaching
            out.
          </p>
          <h6>What does the 💬 symbol mean on some of the listed games?</h6>
          <p>This game is Skillz chat enabled.</p>
          <p>
            This is a feature that Skillz has bestowed upon only a small
            selection of games. This is not a feature that the developer of the
            game can choose to implement, they must be selected by Skillz.
          </p>
          <h6>What does the 👥 symbol mean on some of the listed games?</h6>
          <p>
            This game has some version of the VS Friends challenges. This allows
            players to challenge specific "friends" to a game.
          </p>
          <p>
            This is a feature that Skillz bestows upon only a small selection of
            games. This is not a feature that the developer of the game can
            choose to implement, they must be selected by Skillz.
          </p>
          <h6>What if I find a problem with thist list? 🔎</h6>
          <p>
            While we do our best to keep up with this list, it is a lot of work.
            We check periodically to make sure that each game is still available
            and hasn't been removed from the App Store. If you find that one of
            our links is no longer valid, please let us know and we will dig
            into it to see if the game is either no longer available and needs
            to removed from our list or just needs an updated link. On a similar
            note, finding new Skillz-enabled games to add to our list is
            actually very time consuming as we are not notified when they become
            available (like some select Skillz users are) and this entire list
            has been maintained by our own constant research. So if you know of
            a new game that has not made its way onto our list yet, we
            appreciate any info you can share with us:{" "}
            <a href="mailto:hello@parkerandleigh.com">
              hello@parkerandleigh.com
            </a>
            .
          </p>
          <h6>
            Why does this list not match up with the Skillz game page list? ⚖️
          </h6>
          <p>
            This really comes down to automation. The developer(s) that maintain
            the Skillz list have written their code in such a way that it tries
            it's best to filter their database for them. The way they have
            written their filters will lead to two very different changes
            between their list and ours: First, our list includes some older
            games that you will not find on their page. And, second, you will
            find invalid games listed more frequently on their page. This second
            issue is due to what developers call a "dirty" database. Their
            filters seem to currently allow any newer game entry inputted into
            their database that includes <em>any</em> listed URL in the iOS
            field or <em>any</em> APK into their Android field to appear on
            their page - even if those entries are not valid. If you have ever
            made up a fake email address on a website that is asking for your
            info, this is essentially the same thing happening here.
          </p>

          <h6>Why is a game not monetized? 💸</h6>
          <p>
            The number one reason a game may not be monetzied yet is: lack of
            daily players. For Skillz to allow a game to request monetization, a
            game must consistently have over <strong>100 daily players</strong>.
            We constantly see games get a review in the app store stating: "I'll
            be back when you're monetized." This unfortunately is
            counterproductive to what that developer needs in order to be
            granted monetiztion. They need more people to play DAILY. In this
            phase of a game's journey towards monetization, it is perhaps more
            helpful to that game's progress if you were to play one game a day
            over the course of a month versus 20 games in just one or two days
            and then not return for a month.
          </p>
          <h6>Skillz vs the Game Developer, what's the difference? 👾</h6>
          <p>
            With very few exceptions, the majority of Skillz games are not made
            by Skillz. Think of Skillz like Amazon, they provide the
            infrastructure and marketplace. Game developers can then come and
            sign up to be a part of that marketplace. The games you play are
            made by those different developers, each hoping to be successful
            within the Skillz marketplace. To give you an idea of the diversity,
            this list of games represents over 180 different game developers. So
            when a game is only available for either iOS or Android, but not
            both, that is due to decisions made by that individual game
            developer and not Skillz.
          </p>
          <p>
            Anyone can decide to become a game developer, and Skillz does not
            charge a fee to be a part of their platform. To be a part of Skillz,
            each developer must implement very specific aspects into their code
            to get the Skillz environment up and running within their game. One
            example of this is the code provided by Skillz to ensure that you
            and the other player get the same starting game.
          </p>
          <p>
            Skillz is responsible for your entry fees, pay outs, tickets,
            deposits, player accounts, skill levels, matching, leaderboards,
            playbacks, chats (if applicable), etc. The developer of the game is
            responsible for the actual game play itself as well as any
            additional menus or settings they choose to create. So if you find a
            bug in a game, you should report it to the game developer so they
            become aware of it and can take steps to fix it.
          </p>
          <h6>Can you make actual money? 🤑</h6>
          <p>The short answer is yes.</p>
          <p>You can also lose money too.</p>
          <p>
            We (the people maintaining this list) are a husband and wife team
            that are both Skillz players as well as developers on the Skillz
            platform. To date, the only money we've ever made from Skillz is as
            players. One of us made a single deposit of $10 back when we first
            became players in 2019 and have withdrawn around $900 total between
            the two of us since then. We tend to be conservative players when it
            comes to playing cash games and now really only fund our playing of
            cash games from the bonus cash we get from winning 25 matches in a
            new game. This conservative method of playing means we tend to make
            money (rather than loosing it), but we make money very slowly.
          </p>
          <p>
            The other side of making vs loosing money in the world of Skillz is
            as a game developer. Game developers don't really start to make
            money until their game becomes monetized, which requires a lot of
            daily players. For non-monetized games, Skillz has recently
            introduced an optional ad after some games, but we haven't seen any
            revenue from this yet. The good news is that since Skillz doesn't
            charge a fee for developers to be a part of Skillz, there's
            technically no money lost to get started. But that's not to say that
            there isn't a cost for developers. To properly build out a fairly
            basic game, we would guesstimate it costs roughly $11,000 worth of
            development time. Some developers, like us, write the code
            themselves; while other developers out there will outsource the
            actual code writing to a development team, which of course causes
            them to incur an up front cost. We've also seen other developers
            invest into advertising in an effort to get their game to hit that
            required active daily players mark, which can also be very costly.
            If a developer never reaches that monetiztion point, their
            investment, however much they put into it, is lost.
          </p>
          <h6>Want to Support Us? ♥️</h6>
          <p>
            If you like what we're doing here and want to show your support, we
            do need more daily players in all 3 of our games:
            <br /> <br />
            <a href="https://games.skillz.com/game/19744">
              SpaceSort by Parker and Leigh
            </a>
            <br /> <br />
            <a href="https://games.skillz.com/game/6358">
              Boxes by Parker and Leigh
            </a>
            <br />
            <a href="https://games.skillz.com/game/13652">
              Solitaire by Parker and Leigh
            </a>
            <br />
            <a href="https://games.skillz.com/game/14961">
              Solitaire One by Parker and Leigh
            </a>
          </p>
          <p>
            You can also support us by using our promo code if you are making a
            first time deposit: Fourty2 (you'll also get an extra $20 bonus
            cash).
          </p>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    developmentHero: file(relativePath: { eq: "development-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <SkillzPageFAQ location={props.location} data={data} {...props} />
    )}
  />
)
